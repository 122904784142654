// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\a\\1\\s\\src\\aomrepair-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("D:\\a\\1\\s\\src\\aomrepair-gatsby\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("D:\\a\\1\\s\\src\\aomrepair-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("D:\\a\\1\\s\\src\\aomrepair-gatsby\\src\\pages\\thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\a\\1\\s\\src\\aomrepair-gatsby\\.cache\\data.json")

